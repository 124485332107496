<template>
  <PageLoader class="w-100vw" />
</template>
<script>
import PageLoader from "@/components/preloaders/PageLoader";
export default {
  name: "Loader",
  components: {
    PageLoader,
  },
};
</script>